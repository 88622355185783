<template class="container">
  <InputText
    :type="'text'"
    :name="'years'"
    :autocomplete="'off'"
    :spellcheck="false"
    :small="true"
    :placeholder="'10'"
    :mask="maskRef"
    :maxlength="2"
    :label="'Lata'"
    class="text-input-parent--years"
  />
</template>

<script>
import useIMaskNumber from "@/composables/imask/useIMaskNumber";

export default {
  setup() {
    const { maskRef } = useIMaskNumber();

    return {
      maskRef,
    };
  },
};
</script>

<style scoped>
/* For styles loook into InputText < style > block */

.text-input-parent--years :deep(.text-input__field) {
  text-align: center;
}
.text-input-parent--years :deep(.text-input__field):hover {
  text-align: center;
}
.text-input-parent--years :deep(.text-input__field):focus {
  text-align: center;
}
</style>
