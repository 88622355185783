<template class="container">
  <InputTextYears
    :model-value="modelValue"
    @update:model-value="
      $emit('update:modelValue', $event);
      years = $event;
    "
    @focus="isErrorYears = false"
    @blur="validateYears"
    :error="isErrorYears"
  />
</template>

<script>
import { ref, computed } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

import InputTextYears from "@/components/UI/InputTextYears.vue";

export default {
  components: {
    InputTextYears,
  },

  props: {
    modelValue: String,
  },

  emits: ["update:modelValue", "update:isValidYears"],

  setup(props, { emit }) {
    const years = ref(props.modelValue);

    // Validation on emptyness
    const isErrorYears = ref(false);
    const validateYears = () => {
      isErrorYears.value = years.value?.length === 0 || years.value === "0";
    };

    const isValidYears = computed(() => {
      return years.value && years.value.length > 0 && years.value !== "0";
    });

    watch(
      isValidYears,
      (newIsValid) => emit("update:isValidYears", newIsValid),
      { immediate: true }
    );

    return {
      years,
      isErrorYears,
      validateYears,
      isValidYears,
    };
  },
};
</script>

<style scoped>
/* For styles loook into InputText < style > block */
</style>
