<template>
  <div class="content">
    <ApplicationNannyInputExperienceWorkingYears
      v-model:experience-years="applicationNanny.experience.working.years"
      v-model:experience-comment="applicationNanny.experience.working.comment"
      v-model:is-valid="isValid"
    />

    <ButtonPrimaryNext
      :to="{ name: 'ApplicationNannyCreateExperienceOwnKids' }"
      :isDisabled="!isValid"
    />
  </div>
</template>

<script>
import { ref } from "vue";
import { useApplicationNannyStore } from "@/stores/applicationNanny";

import ButtonPrimaryNext from "@/components/UI/ButtonPrimaryNext.vue";
import ApplicationNannyInputExperienceWorkingYears from "@/components/Application/Nanny/ApplicationNannyInputExperienceWorkingYears.vue";

export default {
  components: {
    ButtonPrimaryNext,
    ApplicationNannyInputExperienceWorkingYears,
  },

  setup() {
    const applicationNanny = useApplicationNannyStore();

    const isValid = ref(false);

    return {
      applicationNanny,
      isValid,
    };
  },
};
</script>

<style scoped>
.content {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 60px;
}

@media (max-width: 1200px) {
}
</style>
