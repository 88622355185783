<template>
  <InputTextTextareaComment
    :model-value="modelValue"
    @update:model-value="
      $emit('update:modelValue', $event);
      comment = $event;
    "
    @focus="isErrorComment = false"
    @blur="validateComment"
    :error="isErrorComment"
  />
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

import InputTextTextareaComment from "@/components/UI/InputTextTextareaComment";

export default {
  components: { InputTextTextareaComment },

  props: {
    modelValue: String,
    validation: {
      type: Boolean,
      default: true,
    },
  },

  emits: ["update:modelValue", "update:isValidComment"],

  setup(props, { emit }) {
    const comment = ref(props.modelValue);

    // Validation on emptyness
    const isErrorComment = ref(false);

    const validateComment = () => {
      if (props.validation) {
        isErrorComment.value = !isValidComment.value;
      } else {
        isErrorComment.value = false;
      }
    };

    const isValidComment = computed(() => {
      if (props.validation === false) {
        return true;
      }
      return typeof comment.value === "string" && comment.value.length > 0;
    });

    watch(
      isValidComment,
      (newIsValid) => emit("update:isValidComment", newIsValid),
      { immediate: true }
    );

    return {
      comment,
      isErrorComment,
      validateComment,
      isValidComment,
    };
  },
};
</script>

<style>
/* For styles loook into InputText < style > block */
</style>
