<template>
  <InputTextTextarea :name="'comment'" :label="'Dodaj komientarz'" />
</template>

<script>
export default {
  components: {},
  setup() {
    return {};
  },
};
</script>

<style>
/* For styles loook into InputText < style > block */
</style>
