<template>
  <div class="container">
    <BaseH1 :text="'Ile lat pracowałaś z dziećmi?'" class="h1 container__h1" />

    <InputTextYearsValidation
      v-model="application.experience.years.value"
      @update:is-valid-years="application.experience.years.isValid = $event"
    />

    <InputTextTextareaCommentValidation
      v-model="application.experience.comment.value"
      @update:is-valid-comment="application.experience.comment.isValid = $event"
    />
  </div>
</template>

<script>
import { computed, reactive, toRefs } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import InputTextYearsValidation from "@/components/UI/InputTextYearsValidation.vue";
import InputTextTextareaCommentValidation from "@/components/UI/InputTextTextareaCommentValidation.vue";

export default {
  components: {
    BaseH1,
    InputTextYearsValidation,
    InputTextTextareaCommentValidation,
  },

  props: {
    experienceYears: Number,
    experienceComment: String,
  },

  emits: [
    "update:experienceYears",
    "update:experienceComment",
    "update:isValid",
  ],

  setup(props, { emit }) {
    const application = reactive({
      experience: {
        years: {
          value: props.experienceYears + "",
          isValid: false,
        },
        comment: {
          value: props.experienceComment,
          isValid: false,
        },
      },
    });

    watch(
      () => application.experience.years.value,
      (newExpYears) => emit("update:experienceYears", Number(newExpYears))
    );
    watch(
      () => application.experience.comment.value,
      (newExpComment) => emit("update:experienceComment", newExpComment)
    );
    watch(Object.values(toRefs(props)), () => {
      application.experience.years.value = props.experienceYears + "";
      application.experience.comment.value = props.experienceComment;
    });

    const isValid = computed(() => {
      return (
        application.experience.years.isValid &&
        application.experience.comment.isValid
      );
    });

    watch(isValid, (newIsValid) => emit("update:isValid", newIsValid), {
      immediate: true,
    });

    return {
      application,
      isValid,
    };
  },
};
</script>

<style scoped>
.container {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 60px;
}
.container__h1 {
  width: 360px;
}
@media (max-width: 1200px) {
  .container {
    width: 100%;

    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 60px;
  }
  .container__h1 {
    width: 100%;
  }
}
</style>
